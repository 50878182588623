.carouselImg {
  /* height: 265px; */
}

@media screen and (min-width: 1200px) and (max-width: 1560px) {
  .carouselImg {
    height: 265px;
    width: unset !important;
  }
}

@media screen and (min-width: 1560px) {
  .carouselImg {
    height: 265px;
    object-fit: cover;
  }
}

/* .carouselImg{
    @media (min-width:1200px) and (max-width:1560px) {height:265px; width: unset;}
}  */

.container {
  background-color: #f9f9fc;
}
.cardCustom {
  background: white;
  height: auto;
  border-radius: 5px;
  padding: 30px;
  min-height: 265px;
}
.cardCustom p {
  font-size: 12px;
  margin-top: 15px;
  color: #808080;
  text-align: start;
}
.cardCustom h5 {
  font-size: 18px;
  font-weight: 500;
  margin-top: 15px;
  text-align: center;
  color: #252525;
}
.carousel-control-prev {
  display: none;
}
.carousel-control-next {
  display: none;
}

.miniCards {
  background: #f9f9fc;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 15px;
  font-size: 12px;
  cursor: pointer;
}
.miniCards img {
  margin-right: 15px;
}
.miniCards p {
  word-break: break-all;
  text-overflow: ellipsis;
  /* width: 50px; */
  overflow: hidden;
  white-space: nowrap;
}
.carousel-indicators [data-bs-target] {
  width: 8px;
  height: 8px;
  padding: 0px;
  margin-right: 6px;
  border-radius: 50%;
}
.carousel-inner {
  border-radius: 5px;
}
.carousel-caption {
  display: flex;
  justify-content: flex-end;
  width: 80%;
}
.carousel-caption button {
  width: 190px;
  height: 52px;
  background-color: #c8dde7;
  outline: none;
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 18px;
}
.IconImg {
  width: 40px;
}
.link {
  color: #077db1 !important;
  font-size: 14px;
  cursor: pointer;
}
.hoverLink {
  font-size: 14px;
  cursor: pointer;
}
.hoverLink:hover {
  color: #077db1;
}
.cardHeading {
  font-size: 18px;
  font-weight: 600;
}
th {
  padding: 15px !important;
  background-color: #eff2f5 !important;
  color: #808080 !important;
  font-weight: 500 !important;
  padding-left: 40px !important;
}
td {
  padding: 15px !important;
  padding-left: 40px !important;
  color: #252525;
}
table {
  font-size: 14px !important;
}
tbody,
td,
tfoot,
th,
thead,
tr {
  border-color: inherit;
  border-style: dashed !important;
  border-width: 0;
}

@media screen and (min-width: 1200px) and (max-width: 1279px) {
  .cardCustom p {
    font-size: 12px;
    margin-top: 15px;
  }
}

@media screen and (min-width: 1874px) {
  .miniCards {
    /* padding: 10px 15px; */
    font-size: 12px;
  }
}
.dashboardDiv{
  background-color: #F9F9FC;
  height: 100vh;
  padding-bottom: 160px;
  overflow: auto;
  margin-top: 65px;
  padding-left: 50px;
}
@media (max-width: 991px) {
  .dashboardDiv {
    padding-left: 0px;
  }
}
.first-row{
  margin-top: 5px;
}