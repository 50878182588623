.header-container{
    background-color: white;
    height: 80px;
    box-shadow: 0 4px 4px #e4e4e440;
    display: flex;
    justify-content: space-between;
    padding: 0px 20px;
    top: 80px;
    z-index: 98;
    position: fixed;
    width: 100%;
    padding-left: 110px;
    border-top: solid 1px #EFF2F5;
}
/* .header-right{
    font-size: 16px;
    font-weight: 500;
    display: flex;
    align-items: center;
    color: #0280B6;
}   
/* .header-right img{
    width: 65px;
} */
.headerIconWrap{
    width: 65px;
    /* background: #EFF2F5; */
    height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.headerIconWrap:hover{
    background-color: #EFF2F5;
}
.header-left{
    font-size: 16px;
    font-weight: 400;
    color: black;
    display: flex;
    align-items: center;
}
.headerIcons{
    cursor: pointer;
    margin:0px 10px;
    width: 20px !important;
}
.header-left img:active{
    filter: invert(30%) sepia(44%) saturate(2813%) hue-rotate(174deg) brightness(101%) contrast(98%);
}
.fontColor >input {
    visibility :hidden;
     width:0px !important;
     height: 0px !important;
 padding:0;
 margin:0;
 }

 
.strokeContainer{
    height: 160px;
    width: 200px;
    /* left: -200px;
    top: 0%; */
    position: absolute;
    background: white;
    box-shadow: 0px 0px 10px rgba(180, 180, 180, 0.25);
    border-radius: 3px;
    margin-top: 20px;
  }
  .strokes{
    width: 100%;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .strokes:hover{
    background-color: #F1FBFF;
  }
  .strokesActive{
    width: 100%;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #F1FBFF;
  }
  .stroke{
    width: 80%;
    background: black;
  }
  .zoomContainer{
    justify-content: center;
    height: 70px;
    width: 280px;
    align-items: center;
    right: 150px;
    position: absolute;
    background: white;
    box-shadow: 0px 0px 10px rgba(180, 180, 180, 0.25);
    border-radius: 3px;
    margin-top: 22px;
    display: flex;
    padding: 0px 20px;
  }

  .alignContainer{
    justify-content: center;
    height: 55px;
    width: 190px;
    align-items: center;
    /* right: 150px; */
    position: absolute;
    background: white;
    box-shadow: 0px 0px 10px rgba(180, 180, 180, 0.25);
    border-radius: 3px;
    margin-top: 20px;
    display: flex;
    padding: 0px 10px;
  }
  .alignIcon{
        /* background: aqua; */
        width: 0px;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
  }

  .activeIcon{
    margin: 0px 5px;
    width: 0px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background-color: rgb(239, 242, 245);
    cursor: pointer;
  }
  .fileNameInput {
    border: none;
    height: 30px;
    padding: 10px;
    outline: none;
    width: 150px;
    margin-right: 15px;
    font-size: 14px;
}
  .Icon{
    margin: 0px 5px;
    width: 0px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    /* background-color: rgb(239, 242, 245); */
    cursor: pointer;
  }