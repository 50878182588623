.staticSidebar {
    height: 100vh;
    background: #53667b;
    position: fixed;
    left: 0vw;
    width: 60px;
    z-index: 9998;
    /* top: 65px; */
    color: white;
    box-shadow: 0 4px 4px #e4e4e440;
  }
  .sideBarIcons {
    /* display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 70px;
    font-size: 12px;
    cursor: pointer;
    margin: 40px 0px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 46px;
    font-size: 12px;
    cursor: pointer;
  }
  .sideBarIcons p {
    margin-top: 5px !important;
    margin-bottom: 0px !important;
  }
  
  .sideBarIcons.active{
     background-color: white;
      /* margin: 40px 5px; */
      color: #53667b;
      border-radius: 5px;
    }
    .sideBarIcons svg{
        filter: none;
       }
    .sideBarIcons.active svg{
      filter: invert(34%) sepia(43%) saturate(2431%) hue-rotate(171deg) brightness(70%) contrast(94%);
     }
     .sideBarIcons.active img{
        filter: invert(34%) sepia(43%) saturate(2431%) hue-rotate(171deg) brightness(70%) contrast(94%);
       }
