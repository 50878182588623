html {
    text-align: center;
    margin: 0;
}
.button {
    background-color: #FFFFFF;
    width: 150px;
    height: 30px;
    -moz-border-radius-topleft: 25px 25px;
    -moz-border-radius-topright: 25px 25px;
    border-top-left-radius: 25px 25px;
    border-top-right-radius: 25px 25px;
    /* color:#fff; */
    text-align: center;
    float:right;
    position: relative;
    right: 40%;
    box-shadow: 0px 0px 10px rgba(180, 180, 180, 0.25);
    cursor: pointer;
}
.drawer {
    position:fixed;
    bottom:65px;
    right:0;
    width:100%;
}
.drawer-appear{clear:both;}
.drawer-inside {
    height: 0;
    width: 100%;
    margin: 0 auto;
    /* overflow: hidden; */
    transition: height .4s ease;
    background: #fff;
    box-shadow: 0px 0px 10px rgba(180, 180, 180, 0.25);
    padding-left: 100px;
}
.drawer-inside.openup {
    height: 150px;
    transition: height .2s;
    display: flex;
    align-items: center;
    overflow: auto;
}
.paginationCardsWrapper{
    display: flex;
    white-space: nowrap;
}
.paginationCards{
    height: 100px;
    width: 200px;
    margin: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor:pointer;
    border-radius: 5px;
    border: 1px solid #EFF2F5;
    position: relative;
    overflow: hidden;

}
.CardActive{
    border: 2px solid #077DB1;
}
.paginationCards img{
    height: 100%;
    width: 100%;
    border-radius: 5px;

}
.pageNumber{
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    width: 47px;
    height: 48px;
    left: -16.62px;
    top: -16px;
    border-radius: 50%;
    display: flex;
    justify-content: flex-end;
    color: white;
    padding-top: 17px;
    padding-right: 13px;
    font-weight: 600;
    font-size: 16px;
}

.pageControls{
    width: 200px;
    position: absolute;
    height: 40px;
    display: none;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.7);
    bottom: 0;
    z-index: 9999;
}
.paginationCards:hover .pageControls{
    display: flex !important;
}