.sidebar {
  position: absolute;
  top: 65px;
  left: -33vw;
  height: 100%;
  width: 42vw;
  transition: left 0.3s ease-in-out;
  background-color: #53667b;
}
.sidebar-toggle {
  position: absolute;
  top: 35%;
  background: #53667b;
  right: -17px;
  height: 80px;
  display: flex;
  width: 17px;
  z-index: 1;
  align-items: center;
  justify-content: center;
  /* border-radius: 10px; */
  border-bottom-right-radius: 25px;
  border-top-right-radius: 25px;
}
.content {
  position: absolute;
  top: 65px;
  left: 0;
  right: 0;
  height: 100%;
  transition: left 0.3s ease-in-out;
  background-color: lightgray;
}
.sidebar.open {
  left: 0;
}
.content.open {
  left: 15vw;
}
.staticSidebar {
  height: 100vh;
  background: #53667b;
  position: fixed;
  left: 0vw;
  width: 60px;
  z-index: 9998;
  /* top: 65px; */
  color: white;
  box-shadow: 0 4px 4px #e4e4e440;
}
.sideBarIcons {
  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 70px;
  font-size: 12px;
  cursor: pointer;
  margin: 40px 0px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 48px;
  font-size: 12px;
  cursor: pointer;
}
.sideBarIcons p {
  margin-top: 5px !important;
  margin-bottom: 0px !important;
}

.sideBarIcons.active{
   background-color: white;
    /* margin: 40px 5px; */
    color: #53667b;
    border-radius: 5px;
  }
  .sideBarIcons.active svg{
    filter: invert(34%) sepia(43%) saturate(2431%) hue-rotate(171deg) brightness(70%) contrast(94%);
   }
  .searchBar {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: #EFF2F5;
    border: none;
    border-radius: 25px;
    width: 345px;
    height: 40px;
    padding: 2px 9px 2px 15px;
    font-size: 12px;
    line-height: 18px;
    white-space: nowrap;
    margin-bottom: 20px;
}
.searchBar img{
  width: 15px;
  margin-right: 10px;
}
.searchBar input {
  outline: none!important;
  height: 30px;
  background: #EFF2F5;
  width: 100%;
  font-size: 14px;
  border: none;
}
.sideBarContents{
  margin-left: 80px;
  margin-top: 25px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.whiteBtn{
  width: 345px;
  height: 40px;
  background-color: white ;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  color: #53667B;
  margin: 20px 0px;
  cursor: pointer;

}
.files-wrapper{
  /* display: flex;
  width: 95%;
  flex-wrap: wrap;
  justify-content: flex-start;
 */
  display: flex;
  align-items: flex-start;
  flex: 1;
  flex-wrap: wrap;
  overflow: auto;
  /* height: 60vh; */
  overflow-y: auto;
  margin-bottom: 20px;
}

.files{
  color: white;
  font-size: 12px;
  font-weight: 300;
  width: 90px;
  height: 90px;
  /* border: 1px dashed #C5C5C5; */
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding:10px 25px;
  margin: 10px 10px;
  cursor: pointer;
  position: relative;
}
.files> img{
  width: 70%;
}
.files .deleteIcon{
  width: 10px;
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  display: none;
}
.files > p {
  margin-top: 6px;
  margin-bottom: 0;
  font-size: 10px !important;
  text-align: center;
  word-break: break-all;
  text-overflow: ellipsis;
  width: 50px;
  overflow: hidden;
  white-space: nowrap;
}
.files:hover {
  border: 1px dashed #C5C5C5;
}
.files:hover .deleteIcon{
  /* border: 1px dashed #C5C5C5; */
  display: block;
}

.images{
  color: white;
  font-size: 12px;
  font-weight: 300;
  width: 90px;
  height: 90px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  /* padding:10px 25px; */
  margin: 10px 10px;
  cursor: pointer;
  position: relative;

}
.images:hover{
  border: 1px dashed #C5C5C5;
}
.images> img{
  width: 70%;
  object-fit: cover;
  max-height: 60px;
}
.images .deleteIcon{
  width: 10px;
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  display: none;
}
.images > p {
  margin-top: 6px;
  margin-bottom: 0;
  font-size: 10px !important;
  text-align: center;
  word-break: break-all;
}
.images:hover {
  border: 1px dashed #C5C5C5;
}
.images:hover .deleteIcon{
  /* border: 1px dashed #C5C5C5; */
  display: block;
}
.imageWrapper{
  display: flex;
  width: 95%;
  flex-wrap: wrap;
  justify-content: flex-start;
  overflow: auto;
  /* height: 55vh; */
}
.text{
  color: white;
  font-size: 12px;
  font-weight: 300;
  width: 100%;
  height: 60px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding:10px 25px;
  margin: 10px 10px;
  cursor: pointer;
  position: relative;
}
.text:hover{
  border: 1px dashed #C5C5C5;

}
.text p{
  margin:0
}
/* ::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
background-color: #EFF2F5;
outline: 1px solid slategrey;


} */
/* ===== Scrollbar CSS ===== */
  /* Firefox */
  * {
    scrollbar-width: auto;
    scrollbar-color: #bebebe #ffffff;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  *::-webkit-scrollbar-track {
    background: none;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #d0d0d0;
    border-radius: 10px;
    border: 3px solid rgb(248, 249, 250);
  }
.footer{
  width: 100%;
  background: #1F2A37;
  color: white;
  height: 65px;
  position: fixed;
  bottom: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  padding: 0px 25px;
}
.css-19kzrtu{
  padding:20px 0px !important;
}
.modal-body{
  padding: 30px !important;
}
.modal-content{
  border-radius: 5px !important;
  border: none !important;
  box-shadow: 0px 0px 10px rgba(180, 180, 180, 0.25);

}
.modal-body h5{
  font-weight: 600;
  margin-bottom: 15px;
  /* font-size: 18px; */
}
.modal-body p{
  font-size: 14px;
}
.modal-btn{
  display: flex;
  justify-content: flex-end;
  font-size: 14px;
  margin-top: 25px;
}
.cancel-btn{
  height: 31px;
  width: 80px;
  border: 1px solid #077DB1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #212121;
  font-weight: 600;
  border-radius: 5px;
  cursor: pointer;
}
.cancel-btn-exp{
  height: 31px;
  width: 120px;
  border: 1px solid #077DB1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #212121;
  font-weight: 600;
  border-radius: 5px;
  cursor: pointer;
}
.confirm-btn{
  height: 31px;
  width: 80px;
  /* border: 1px solid #1F2A37; */
  display: flex;
  justify-content: center;
  align-items: center;
  color:white;
  font-weight: 600;
  border-radius: 5px;
  margin-left: 20px;
  background-color:#DC3545;
  cursor: pointer;
}
.save-btn{
  height: 31px;
  width: 120px;
  /* border: 1px solid #1F2A37; */
  display: flex;
  justify-content: center;
  align-items: center;
  color:white;
  font-weight: 400;
  border-radius: 5px;
  margin-left: 15px;
  background-color:#077DB1;
  cursor: pointer;
}
.exportOptions{
   display: flex;
   margin-top: 25px;
}
.exportOptionBox{
  height: 100px;
  width: 100px;
  background: #F3F3F3;;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: #717171;
  margin-right: 10px;
  cursor: pointer;
}
.exportOptionBox p{
  font-size: 10px !important;
  margin-bottom: 0;
  margin-top: 10px;
}
.exportOptionBox img:active{
  filter: invert(30%) sepia(44%) saturate(2813%) hue-rotate(174deg) brightness(101%) contrast(98%);
}
.exportModal{
  /* background:#077DB1; */
  width: 350px !important; 
  margin-right: 0 !important;
  top: 105px !important;
  right: 1%;
}
.modal-backdrop {

  background-color: #000 !important;
}

.mainWrapper{
  background: rgb(248, 249, 250);
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 82px !important;
}


.shapes{
  color: white;
  font-size: 12px;
  font-weight: 300;
  width: 90px;
  height: 90px;
  /* border: 1px dashed #C5C5C5; */
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding:10px 10px;
  margin: 10px 10px;
  cursor: pointer;
  position: relative;
}
.shapes> img{
  width: 70%;
}
.shapes:hover {
  border: 1px dashed #C5C5C5;
}
.alignmentWrapper{
  width: 70%;
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
}
.MuiDrawer-paper{
  /* margin-top: 85px; */
}
.modal-backdrop{
  opacity: 0 !important;
}
.flex{
  display:flex;
}