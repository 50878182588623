.main{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    flex-direction: column;
    font-size: 12px;
}
.main img{
    width: 200px;
    margin: 25px;
}
.main a{
    margin-top: 20px;
    text-decoration: none;
}