
.toastBody{
    display: flex;
    align-items: center;
    justify-content: center;
}
.toast{
    background: rgba(92, 192, 13, 0.05);
    color: #212121;
    border: none;
    box-shadow: none;
    height: 50px;
  }