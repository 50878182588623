.global-header-container{
    background-color: white;
    height: 80px;
    /* box-shadow: 0 4px 4px #e4e4e440; */
    border-bottom: 1px solid #EFF2F5;
    display: flex;
    justify-content: space-between;
    padding: 0px 20px;
    z-index: 1200;
    position: fixed;
    width: 100%;
    padding-left: 20px;
   
}
/* @media (max-width: 991px) {
  .global-header-container {
    display: none;
  }
} */
.header-left{
    font-size: 18px;
    font-weight: 500;
    display: flex;
    align-items: center;
    color: #252525;
}   
.header-left img{
  width: 36px;
  height: 36px;
  background: #FFFFFF;
  border-radius: 5px;
}
.header-right img{
    width: 30px; 
    margin: 0px 67px;
}
.header-center{
    display: flex;
    justify-content: center;
    align-items: center;
}
.globalSearchbar{
    width: 300px;
    height: 30px;
    background-color: #EFF2F5;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.globalSearchbar img{
    margin: 0px 10px;
    width: 15px;
}
.globalSearchbar input{
    background:#EFF2F5;
    border: none;
    height: 30px;
    padding: 10px;
    padding-left: 5px;
    outline: none;
    width: 100%;
    border-radius: 5px;
    font-size: 12px;
}
.globalSearchbar > input:focus {
    /* outline: solid 1px #BEBEBE; */
    border-radius: 5px;
  }
   
  .header-right{
    font-size: 16px;
    font-weight: 400;
    color: black;
    display: flex;
    align-items: center;
    padding-right: 15px;
}
.headerIcons{
    cursor: pointer;
    margin-right: 0px 10px;
}
.header-left img:active{
    filter: invert(30%) sepia(44%) saturate(2813%) hue-rotate(174deg) brightness(101%) contrast(98%);
}

.profileIcon{
    width: 40px !important;
    height: 40px !important;
    border-radius: 50% !important;
    filter: none !important;
    cursor: pointer;
}

.profileContainer {
    /* justify-content: center; */
    /* height: 470px; */
    width: 315px;
    /* align-items: center; */
    right: 25px;
    position: absolute;
    top: 48px;
    background: white;
    box-shadow: 0px 0px 10px rgba(180, 180, 180, 0.25);
    border-radius: 3px;
    margin-top: 22px;
    display: flex;
    padding: 10px 0px;
    z-index: 99 !important;
    max-height: 80vh;
    overflow-y: auto;
    flex-direction: column;
    
}   
.profileContainer hr{
    margin: 5px !important;
}
.drop-menu-header{
    font-weight: 500;
    font-size: 13px;
    color: gray;
    padding: 7px 20px;
    display: flex;
    /* margin-bottom: 20px; */
}
.org-list{
    max-height: 90px;
    overflow-y: auto;
}
.drop-menu-item{
    color: #252525;
    display: flex;
    align-items: center;
    padding: 0px 20px;
    cursor: pointer;
}
.org-initial {
    font-weight: 600;
    font-size: 14px;
    color: gray;
    background-color: #077db10d;
    border-radius: 5px;
    margin-right: 16px;
    min-height: 44px;
    min-width: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.org-name{
    color: #252525;
    font-weight: 400;
    font-size: 12px;
    text-align: left;
}
.drop-menu-item:hover {
    background-color: #eff2f5;
}

.MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.2) !important;
  }

  .confirm-btn-feedback{
    align-items: center;
    height: 35px;
    font-size: 14px;
    background-color: #077db1;
    border-radius: 3px;
    box-shadow: 0 0 10px #b4b4b440;
    display: flex;
    justify-content: center;
    color: #fff;
    border: 1px solid #077DB1;
    cursor: pointer;
    width: 50%;
    margin-left: 10px;
  }
  .cancel-btn-feedback{
    align-items: center;
    height: 35px;
    border: 1px solid #077DB1;
    border-radius: 3px;
    width: 50%;
    display: flex;
    justify-content: center;
    color: #000;
    font-size: 14px;
    background: #FFFFFF;
    cursor: pointer;
    margin-right: 10px;
  }
  .modal-btn-feedback{
    display: flex;
    width: 100%;
    justify-content: space-between  ;
  }
  
  .material-select {
    position: relative;
    margin-bottom: 30px;
  }
  
  .material-select label {
    position: absolute;
    font-size: 0.8rem;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background-color: white !important;
    color: #757575;
    padding: 0 0.3rem;
    margin: 0 0.5rem;
    transition: .1s ease-out;
    transform-origin: left top;
    pointer-events: none;
  }
  
  .material-select .down-arrow {
    position: absolute;
    right: 10px;
    top: 10px;
  }
  
  .material-select select {
    font-size: 1rem;
    outline: none;
    border: 1px solid #B4B4B4;
    border-radius: 5px;
    padding: 0.7rem 0.7rem;
    color: black;
    transition: 0.1s ease-out;
    width: 100%;
    appearance: none;
    background: #FFFF;
  }
  
  .material-select select:focus {
    border-color: #757575;
  }
  
  .material-select select:focus+label {
    color: #757575;
    top: 0;
    transform: translateY(-50%) scale(.9);
  }
  
  .material-select select:not(:placeholder-shown)+label {
    top: 0;
    transform: translateY(-50%) scale(.9);
  }
  
  select option {
    margin: 40px;
    background: #FFFF;
    color: rgba(0, 0, 0, 1);
  }
  
  .error-input select {
    border: 1px solid #DC3545 !important;
  }
  .material-select .down-arrow {
    position: absolute;
    right: 10px;
    top: 10px;
  }
  .validation-error {
    font-weight: 400;
    font-size: 12px;
    color: #DC3545;
  } 


  
  .material-textfield {
    position: relative;
    margin-bottom: 30px;
  }
  
  .material-textfield label {
    position: absolute;
    font-size: 0.8rem;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background-color: white;
    color: #757575;
    padding: 0 0.3rem;
    margin: 0 0.5rem;
    transition: .1s ease-out;
    transform-origin: left top;
    pointer-events: none;
  }
  
  .material-textfield textarea {
    font-size: 1rem;
    outline: none;
    border: 1px solid #B4B4B4;
    border-radius: 5px;
    padding: 0.7rem 0.7rem;
    color: black;
    transition: 0.1s ease-out;
    width: 100%;
  }
  
  .material-textfield textarea:focus {
    border-color: #757575;
  }
  
  .material-textfield textarea:focus+label {
    color: #757575;
    top: 0;
    transform: translateY(-50%) scale(.9);
  }
  
  .material-textfield textarea:not(:placeholder-shown)+label {
    top: 0;
    transform: translateY(-50%) scale(.9);
  }
  
  .error-input textarea {
    border: 1px solid #DC3545 !important;
  }
  .hamburger:active{
    /* outline: none; */
    background-color: #efefef;
  }
  .hamburger{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    /* outline: auto; */
    cursor: pointer;
    padding-left: 20px;
  }