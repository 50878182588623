@font-face {
  font-family: Poppins Sans;
  src: url(fonts/poppins-regular-webfont.woff2) format('woff2'),
}
@font-face {
  font-family: Times New Roman;
  src: url(fonts/Times-New-Roman.woff2) format('woff2'),
}
@font-face {
  font-family: Roboto;
  src: url(fonts/roboto-regular.woff2) format('woff2'),
}
@font-face {
  font-family: Unbounded;
  src: url(fonts/Unbounded-Regular.woff2) format('woff2'),
}
@font-face {
  font-family: Montserrat;
  src: url(fonts/Montserrat-Regular.woff2) format('woff2'),
}
@font-face {
  font-family: RobotoMono;
  src: url(fonts/Roboto-Mono-regular.woff2) format('woff2'),
}



.whiteboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  // border: 1px solid;
  background: white; 
  width: 100%;
  
  canvas {
    // border: 1px solid rgb(165, 165, 165);
    box-shadow: 0px 1px 5px rgba(162, 162, 162, 0.25);
    border-radius: 5px;
    border-radius: 3px;
  }

  button {
    padding: 12px;
    margin: 1px;
    background-color: white;
    border-radius: 3px;
    color: black;
    border: none;
    outline: none;
    cursor: pointer;

    &:hover {
      background-color: white;
    }
  }
}
.drawTools{
  position: fixed;
  right: 20px;
  top: 25%;
  z-index: 98;
  box-shadow: 0px 0px 10px rgba(180, 180, 180, 0.25);
  border-radius: 3px;
  .tools{
    color: #53667B;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 0px
    ;
    font-size: 12px;
    width: 90px;
    cursor: pointer;
    &:hover {
      background-color: #F1FBFF;
    }
  p{
    margin-bottom: 0;
    margin-top: 10px
  }
  }
  .toolsActive{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 0px;
    font-size: 12px;
    width: 90px;
    cursor: pointer;
    background-color: #F1FBFF;
    p{
      margin-bottom: 0;
      margin-top: 10px
    }
  }
}
.whiteBoardWrap{
  // overflow: auto;
  max-height: 60vh;
  background:rgb(248, 249, 250);
}

.bottomToolBar{
  position: fixed;
  bottom: 11%;
  padding: 0px 25px;
  display: flex;
  height: 70px;
  align-items: center;
  z-index: 999;
  box-shadow: 0px 0px 10px rgba(180, 180, 180, 0.25);
  border-radius: 3px;
  margin: 0px 10px;
  background-color: white;
  .fileNameInput{
    border: none;
    height: 30px;
    padding: 10px;
    // outline: none;
    width: 180px;
  }
  .editIcon{
    display: none;
  }
  .fileNameInput:focus .editIcon{
      display: block;
  }

}

.divCenter{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

input[type="color"] {
	-webkit-appearance: none;
	border: none;
	width: 32px;
	height: 32px;
}
input[type="color"]::-webkit-color-swatch-wrapper {
	padding: 0;
}
input[type="color"]::-webkit-color-swatch {
	border: none;
}

.toolbar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  img {
    width: 15px;
    vertical-align: middle;
  }

  input {
    margin: 0 2px;
  }

  input:not([type='checkbox']) {
    width: 50px;
  }
}

.dropdownButton {
  background-color: white;
  color: white;
  padding: 11px 14px;
  font-size: 14px;
  border: none;
}

.uploadDropdown {
  position: relative;
  display: inline-block;

  input {
    display: none;
  }
}

.dropdownContent {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdownContent span {
  display: block;
  color: black;
  padding: 12px 16px;
  cursor: pointer;
}

.dropdownContent span:hover {
  background-color: #ddd;
}

.uploadDropdown:hover .dropdownContent {
  display: block;
}

.uploadDropdown:hover .dropdownButton {
  background-color: #7173ee;
}

.strokeContainer{
  height: 160px;
  width: 200px;
  left: -200px;
  top: 0%;
  position: absolute;
  background: white;
  box-shadow: 0px 0px 10px rgba(180, 180, 180, 0.25);
  border-radius: 3px;
}
.strokes{
  width: 100%;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.strokes:hover{
  background-color: #F1FBFF;
}
.strokesActive{
  width: 100%;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F1FBFF;
}
.stroke{
  // height: 10px;
  width: 80%;
  background: black;
}